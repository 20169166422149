import moment from "moment";
import {
  Artifact,
  EndCustomer,
  Shipment,
  ShipmentEdge,
  ShipmentStatus,
  ShipmentStopType,
  Stop,
  Task,
  TimeWindow,
} from "@api/graphql/generated/generated-types";
import { ShipmentRow } from "@src/shipments/types";
import { makeStopDisplayStatusBadge } from "@src/shipments/utils/stopBadgeFormatters";
import {
  chooseIconBasedOnStatus,
  displayShipmentStatus,
} from "@src/shipments/utils/shipmentStatusDisplayer";
import { makeDriverMapItem } from "@src/drivers/utils/driverMapItemMaker";
import { makeDateFromEpochSeconds } from "@src/common/lib/DateUtils";
import { findIfShipmentIsMultiDay } from "@src/common/lib/shipmentStateFinders";

export const generateTooltipContent = (pkg: any) => {
  let content = "";

  for (let [key, value] of Object.entries(pkg)) {
    if (value !== undefined && value !== "") {
      let tooltipKey = "";

      switch (key) {
        case "numPackages":
          tooltipKey = "Quantity";
          break;
        case "packageSize":
          tooltipKey = "Size";
          break;
        case "packageWeightInPounds":
          tooltipKey = "Weight";
          break;
        default:
          tooltipKey = key;
          break;
      }

      // Add to content string
      content += `${tooltipKey}: ${value}, `;
    }
  }

  // Remove trailing comma and space, return content
  return content.slice(0, -2);
};

function sortStopsBySequenceNumber(
  stops: Stop[],
  stopType: ShipmentStopType
): Stop[] {
  const filteredStops = stops.filter((stop) => stop.type === stopType);
  if (filteredStops.length > 1) {
    filteredStops.sort((a, b) => {
      const sequenceA =
        a.tasks && a.tasks[0]
          ? a.tasks[0].sequenceNumber
          : Number.MAX_SAFE_INTEGER;
      const sequenceB =
        b.tasks && b.tasks[0]
          ? b.tasks[0].sequenceNumber
          : Number.MAX_SAFE_INTEGER;
      return sequenceA - sequenceB;
    });
  }
  return filteredStops;
}

export function makeShipmentEdgesToShipmentRow(
  shipmentEdges: ShipmentEdge[]
): ShipmentRow[] {
  const rows: ShipmentRow[] = shipmentEdges.map((edge: ShipmentEdge) => {
    const shipment: Shipment | undefined | null = edge?.node;
    const endCustomer = shipment?.order.endCustomer;
    const pickupStop = findStopByType(shipment?.stops, "PICK_UP");
    const dropoffStop = findStopByType(shipment?.stops, "DROP_OFF");

    const pickUpEta = getEarliestEta(pickupStop?.tasks || []);
    const dropOffEta = getEarliestEta(dropoffStop?.tasks || []);

    const pickUpStops = shipment?.stops
      ? sortStopsBySequenceNumber(shipment.stops, ShipmentStopType.PickUp)
      : [];
    const dropOffStops = shipment?.stops
      ? sortStopsBySequenceNumber(shipment.stops, ShipmentStopType.DropOff)
      : [];

    if (shipment) {
      return {
        key: shipment.id,
        id: shipment.id,
        recurrenceType: shipment.order.recurrenceType,
        status: displayShipmentStatus(shipment),
        shipmentStatus: shipment.status,
        StatusIcon: chooseIconBasedOnStatus(shipment),
        orderDisplayId: shipment.order.displayId || "-",
        customerDisplayName: makeCustomerDisplayName(endCustomer),
        pickup: {
          badgeMeta: makeStopDisplayStatusBadge(
            pickupStop?.timeStatus || undefined,
            pickupStop?.timeWindow,
            true
          ),
          timeWindowText: getTimeWindowText(pickupStop?.timeWindow, false),
          eta: getEtaText(pickUpEta),
          isLikelyLate: Boolean(
            pickUpEta &&
              pickupStop?.timeWindow?.close &&
              pickUpEta > pickupStop?.timeWindow?.close
          ),
          taskStatusList:
            pickupStop?.tasks?.map((task: Task) => task.status) || [],
          arrivedAt: getArrivedAtText(pickupStop?.tasks || []),
          completedAt: getCompletedAtText(pickupStop),
          instruction: pickupStop?.instruction || "",
          pickUpStops: pickUpStops || [],
        },
        dropoff: {
          badgeMeta: makeStopDisplayStatusBadge(
            dropoffStop?.timeStatus || undefined,
            dropoffStop?.timeWindow,
            false
          ),
          timeWindowText: getTimeWindowText(dropoffStop?.timeWindow, false),
          eta: getEtaText(dropOffEta),
          isLikelyLate: Boolean(
            dropOffEta &&
              dropoffStop?.timeWindow?.close &&
              dropOffEta > dropoffStop?.timeWindow?.close
          ),
          taskStatusList:
            dropoffStop?.tasks?.map((task: Task) => task.status) || [],
          arrivedAt: getArrivedAtText(dropoffStop?.tasks || []),
          completedAt: getCompletedAtText(dropoffStop),
          instruction: dropoffStop?.instruction || "",
          dropOffStops: dropOffStops || [],
        },
        origin: {
          dispatchZone: pickupStop?.dispatchZone || "",
          streetAddress: pickupStop?.streetAddress || "",
          dispatchZoneLine2: pickupStop?.dispatchZoneLine2 as
            | string
            | undefined,
        },
        destination: {
          dispatchZone: dropoffStop?.dispatchZone || "",
          streetAddress: dropoffStop?.streetAddress || "",
          dispatchZoneLine2: dropoffStop?.dispatchZoneLine2 as
            | string
            | undefined,
        },
        package: {
          numPackages: shipment.numPackages || undefined,
          packageSize: shipment.packageSize || "",
          packageWeightInPounds: shipment.packageWeightInPounds || undefined,
        },
        packageDescription: shipment.packageDescription || "",
        shipmentDate: shipment.shipmentDate,
        serviceType: shipment.order.serviceType?.name || "",
        serviceTypeColor: shipment.order.serviceType?.color || undefined,
        driverId: shipment.driver?.id || undefined,
        driverPhotoUrl: shipment.driver?.photoUrl || undefined,
        driverName: shipment.driver
          ? `${shipment.driver?.firstName} ${shipment.driver?.lastName}`
          : undefined,
        driverMapItem:
          shipment.driver && shipment.driver.lastReportedLocation
            ? makeDriverMapItem(shipment.driver, undefined)
            : undefined,
        canUnassignDriver:
          shipment.driver !== undefined &&
          (shipment.status === ShipmentStatus.Assigned ||
            shipment.status === ShipmentStatus.Routed),
        canChangeRouting:
          shipment.status === ShipmentStatus.Routed ||
          shipment.status === ShipmentStatus.InTransit,
        canDeleteOrder:
          shipment.status === ShipmentStatus.Created ||
          shipment.status === ShipmentStatus.Assigned ||
          shipment.status === ShipmentStatus.Failed ||
          shipment.status === ShipmentStatus.Routed,
        canFailOrder: shipment.status != ShipmentStatus.Completed,
        canCloseOrder:
          shipment.status === ShipmentStatus.Completed && !shipment.isClosed,
        canUncloseOrder:
          shipment.status === ShipmentStatus.Completed && shipment.isClosed,
        canForceCompleteOrder: shipment.status !== ShipmentStatus.Completed,
        artifactsAtStops: shipment?.stops
          ?.filter((stop: Stop) => stop.artifacts.length > 0)
          .map((stop: Stop) => {
            return {
              stopId: stop.id,
              stopAddress: stop.streetAddress,
              stopType:
                stop.type === ShipmentStopType.PickUp ? "Pick up" : "Drop off",
              artifacts: stop.artifacts.map((artifact: Artifact) => {
                return {
                  id: artifact.id,
                  url: artifact.publicUrl || "",
                  caption: artifact.value || undefined,
                  type: artifact.type,
                  timestamp: makeDateFromEpochSeconds(artifact.createdAt),
                };
              }),
            };
          }),
        isCompleted: shipment.status === ShipmentStatus.Completed,
        shipmentType: shipment.type,
        shipment: shipment,
        isMultiDay: findIfShipmentIsMultiDay(
          shipment.stops || [],
          shipment.shipmentDate
        ),
      };
    }
  });
  return rows || undefined;
}

export function makeCustomerDisplayName(endCustomer: EndCustomer | undefined) {
  return (
    endCustomer?.companyName ||
    endCustomer?.contactEmail ||
    endCustomer?.firstName ||
    ""
  );
}

function findStopByType(
  stops: Stop[] | undefined,
  type: string
): Stop | undefined {
  if (!stops) {
    return undefined;
  }
  return stops.find((stop: any) => stop.type === type);
}

export function getTimeWindowText(
  timeWindow: TimeWindow | undefined,
  showFullTimeWindow: boolean = true,
  showDate: boolean = false
): string | undefined {
  if (!timeWindow) {
    return undefined;
  }

  const TIME_FORMAT = "h:mm a, M/DD";
  const TIME_FORMAT_NO_DATE = "h:mm a";
  const hasOpenTime =
    timeWindow?.open !== null && timeWindow?.open !== undefined;
  const hasCloseTime =
    timeWindow?.close !== null && timeWindow?.close !== undefined;
  const openingTimestampUtc = moment.utc(parseInt(timeWindow.open) * 1000);
  const closingTimestampUtc = moment.utc(parseInt(timeWindow.close) * 1000);
  const localOpeningTime = openingTimestampUtc.local();
  const localClosingTime = closingTimestampUtc.local();

  // if open is undefined and close exists then return text as "Due at HH:MM"
  if (!hasOpenTime && hasCloseTime) {
    return `${showFullTimeWindow ? "Due " : ""}${localClosingTime.format(
      showDate ? TIME_FORMAT : TIME_FORMAT_NO_DATE
    )}`;
  } else if (hasOpenTime && !hasCloseTime) {
    return `${showFullTimeWindow ? "Ready " : ""}${localOpeningTime.format(
      showDate ? TIME_FORMAT : TIME_FORMAT_NO_DATE
    )}`;
  }

  // if open and close exists then return text as "HH:MM - HH:MM"
  else if (hasOpenTime && hasCloseTime) {
    return `${localOpeningTime.format(
      showDate ? TIME_FORMAT : TIME_FORMAT_NO_DATE
    )} - ${localClosingTime.format(
      showDate ? TIME_FORMAT : TIME_FORMAT_NO_DATE
    )}`;
  }
  // if open and close are undefined then return text as "No time window"
  else {
    return "None";
  }
}

function getEtaText(eta: number | undefined): string | undefined {
  if (!eta) {
    return undefined;
  }
  const TIME_FORMAT = "h:mm a";
  const earliestEtaTimestampUtc = moment.utc(eta * 1000);
  const localEarliestEta = earliestEtaTimestampUtc.local();

  return localEarliestEta.format(TIME_FORMAT);
}

function getEarliestEta(tasks: Task[]): number | undefined {
  if (tasks.length === 0) {
    return undefined;
  }
  return tasks.reduce((earliestEta, task) => {
    if (task.etaEpochSeconds < earliestEta) {
      return task.etaEpochSeconds;
    }
    return earliestEta;
  }, tasks[0].etaEpochSeconds);
}

function getArrivedAtText(tasks: Task[]): string | undefined {
  const taskWithArrivedAt = tasks.find((task) => task.arrivedAt);
  if (!taskWithArrivedAt) {
    return undefined;
  }

  const arrivedAtTimestampUtc = moment.utc(taskWithArrivedAt.arrivedAt * 1000);
  const arrivedAtTimestampLocal = arrivedAtTimestampUtc.local();
  return moment(arrivedAtTimestampLocal).format("h:mm a");
}

export function getCompletedAtText(stop: Stop | undefined): string | undefined {
  if (!stop) {
    return undefined;
  }
  if (!stop.completedAt) {
    return undefined;
  }
  const completedAtTimestampUtc = moment.utc(stop.completedAt * 1000);
  const completedAtTimestampLocal = completedAtTimestampUtc.local();
  return moment(completedAtTimestampLocal).format("h:mm a");
}
