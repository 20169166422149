import {
  RoutingJobStatus,
  Shipment,
  ShipmentStatus,
} from "@api/graphql/generated/generated-types";
import {
  BeakerIcon,
  CheckBadgeIcon,
  CheckIcon,
  ForwardIcon,
  PauseIcon,
  QuestionMarkCircleIcon,
  TrashIcon,
  UserCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { FC } from "react";

export function displayShipmentStatus(shipment: Shipment) {
  const { status, isClosed, isForceCompleted, driverConfirmedAt } = shipment;

  if (isClosed) {
    return "Closed";
  }
  if (isForceCompleted && status === ShipmentStatus.Completed) {
    return "Force Completed";
  }
  switch (status) {
    case ShipmentStatus.Routed:
    case ShipmentStatus.Assigned:
      if (driverConfirmedAt) {
        return "Confirmed";
      }
      return "Unconfirmed";
    case ShipmentStatus.Completed:
      return "Delivered";
    case ShipmentStatus.Created:
      if (shipment.routingJob?.status === RoutingJobStatus.OptimizationFailed) {
        return "Optimization Failed";
      }
      if (shipment.routingJob?.status === RoutingJobStatus.Ready) {
        return "Ready";
      }
      if (shipment.routingJobId) {
        return "Optimizing";
      }
      return "Unassigned";
    case ShipmentStatus.Failed:
      return "Failed";
    case ShipmentStatus.InTransit:
      return "In Transit";
    case ShipmentStatus.Paused:
      return "Paused";
    case ShipmentStatus.Deleted:
      return "Deleted";
  }
}

export function chooseIconBasedOnStatus(
  shipment: Shipment
): FC<React.SVGProps<SVGSVGElement>> {
  const { status, isClosed } = shipment;
  if (isClosed) {
    return CheckBadgeIcon as FC<React.SVGProps<SVGSVGElement>>;
  }
  switch (status) {
    case ShipmentStatus.Assigned:
      return UserCircleIcon as FC<React.SVGProps<SVGSVGElement>>;
    case ShipmentStatus.Routed:
      return UserCircleIcon as FC<React.SVGProps<SVGSVGElement>>;
    case ShipmentStatus.Completed:
      return CheckIcon as FC<React.SVGProps<SVGSVGElement>>;
    case ShipmentStatus.Created:
      if (shipment.routingJob?.status === RoutingJobStatus.OptimizationFailed) {
        return BeakerIcon as FC<React.SVGProps<SVGSVGElement>>;
      }
      if (shipment.routingJobId) {
        return BeakerIcon as FC<React.SVGProps<SVGSVGElement>>;
      }
      return QuestionMarkCircleIcon as FC<React.SVGProps<SVGSVGElement>>;
    case ShipmentStatus.Failed:
      return XCircleIcon as FC<React.SVGProps<SVGSVGElement>>;
    case ShipmentStatus.InTransit:
      return ForwardIcon as FC<React.SVGProps<SVGSVGElement>>;
    case ShipmentStatus.Paused:
      return PauseIcon as FC<React.SVGProps<SVGSVGElement>>;
    case ShipmentStatus.Deleted:
      return TrashIcon as FC<React.SVGProps<SVGSVGElement>>;
  }
}
