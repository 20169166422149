import moment from "moment";
import {
  StopTimeStatus,
  TimeWindow,
} from "@api/graphql/generated/generated-types";

export const STATUS_BADGE_COLORS = [
  {
    status: StopTimeStatus.LateSoon,
    flowbiteColor: "warning",
    color: "orange",
  },
  {
    status: StopTimeStatus.Completed,
    flowbiteColor: "success",
    color: "green",
  },
  { status: StopTimeStatus.Late, flowbiteColor: "failure", color: "red" },
  {
    status: StopTimeStatus.LikelyLate,
    flowbiteColor: "warning",
    color: "orange",
  },
  { status: StopTimeStatus.NotReady, flowbiteColor: "purple", color: "purple" },
  { status: StopTimeStatus.OnTime, flowbiteColor: "info", color: "blue" },
];

export type BadgeMeta = {
  badgeColor: string | undefined;
  status: StopTimeStatus | undefined;
  badgeText: string;
  backgroundColor: string | undefined;
  shouldShowBadge: boolean;
};

export function makeStopDisplayStatusBadge(
  stopTimeStatus: StopTimeStatus | undefined,
  timeWindow: TimeWindow | undefined,
  isPickUp: boolean
): BadgeMeta {
  const badgeColor = STATUS_BADGE_COLORS.find(
    (badge) => badge.status === stopTimeStatus
  );
  const status = stopTimeStatus;
  const badgeText: string = makeStopDisplayTimeWindowBadge(
    stopTimeStatus,
    timeWindow
  );
  return {
    badgeColor: badgeColor?.flowbiteColor,
    status,
    badgeText,
    backgroundColor: badgeColor?.color,
    shouldShowBadge: stopTimeStatus !== StopTimeStatus.OnTime,
  };
}

export function makeStopDisplayTimeWindowBadge(
  stopTimeStatus: StopTimeStatus | null | undefined,
  timeWindow: TimeWindow | undefined
): string {
  if (stopTimeStatus === StopTimeStatus.Completed) {
    return "";
  }
  const hasOpenTime =
    timeWindow?.open !== null && timeWindow?.open !== undefined;
  const hasCloseTime =
    timeWindow?.close !== null && timeWindow?.close !== undefined;
  if (!timeWindow || (!hasOpenTime && !hasCloseTime)) {
    return "";
  }
  if (!hasCloseTime) {
    return makeStopDisplayTimeWindowBadgeWithOpenTimeOnly(
      stopTimeStatus,
      timeWindow
    );
  }

  if (!hasOpenTime) {
    return makeStopDisplayTimeWindowBadgeWithCloseTimeOnly(
      stopTimeStatus,
      timeWindow
    );
  }

  const windowOpen: Date = new Date(parseInt(timeWindow.open) * 1000);
  const windowClose: Date = new Date(parseInt(timeWindow.close) * 1000);
  const currentTime = new Date();
  const timeDifferenceOpenInMillis = Math.abs(
    windowOpen.getTime() - currentTime.getTime()
  );
  const timeDifferenceCloseInMillis = Math.abs(
    windowClose.getTime() - currentTime.getTime()
  );
  if (stopTimeStatus === StopTimeStatus.NotReady) {
    if (Math.abs(windowOpen.getTime() - windowClose.getTime()) < 1000) {
      return `Exactly in ${moment
        .duration(timeDifferenceOpenInMillis)
        .humanize()}`;
    }

    return `Ready in ${moment.duration(timeDifferenceOpenInMillis).humanize()}`;
  }
  // stopType === "READY" | Closes In 1 hour |  current_timestamp ≥ window_open is true
  if (
    stopTimeStatus === StopTimeStatus.OnTime ||
    stopTimeStatus === StopTimeStatus.LateSoon ||
    stopTimeStatus === StopTimeStatus.LikelyLate
  ) {
    if (timeWindow.close === null || timeWindow.close === undefined) {
      return "";
    }
    return `Due in ${moment.duration(timeDifferenceCloseInMillis).humanize()}`;
  }
  // stopType ===  "LATE" | Late By x | current_timestamp > window_close
  if (stopTimeStatus === StopTimeStatus.Late) {
    return `Late by ${moment.duration(timeDifferenceCloseInMillis).humanize()}`;
  }
  return "";
}

function makeStopDisplayTimeWindowBadgeWithCloseTimeOnly(
  stopTimeStatus: StopTimeStatus | null | undefined,
  timeWindow: TimeWindow | undefined
): string {
  if (!timeWindow) {
    return "";
  }
  const windowClose: Date = new Date(parseInt(timeWindow.close) * 1000);
  const currentTime = new Date();
  const timeDifferenceCloseInMillis = Math.abs(
    windowClose.getTime() - currentTime.getTime()
  );
  if (
    stopTimeStatus === StopTimeStatus.NotReady ||
    stopTimeStatus === StopTimeStatus.OnTime ||
    stopTimeStatus === StopTimeStatus.LateSoon ||
    stopTimeStatus === StopTimeStatus.LikelyLate
  ) {
    return `Due in ${moment.duration(timeDifferenceCloseInMillis).humanize()}`;
  }
  if (stopTimeStatus === StopTimeStatus.Late) {
    return `Late by ${moment.duration(timeDifferenceCloseInMillis).humanize()}`;
  }
  return "";
}

function makeStopDisplayTimeWindowBadgeWithOpenTimeOnly(
  stopTimeStatus: StopTimeStatus | null | undefined,
  timeWindow: TimeWindow | undefined
): string {
  if (!timeWindow) {
    return "";
  }
  const windowOpen: Date = new Date(parseInt(timeWindow.open) * 1000);
  const currentTime = new Date();
  const timeDifferenceOpenInMillis = Math.abs(
    windowOpen.getTime() - currentTime.getTime()
  );
  if (stopTimeStatus === StopTimeStatus.NotReady) {
    return `Ready in ${moment.duration(timeDifferenceOpenInMillis).humanize()}`;
  }
  if (
    stopTimeStatus === StopTimeStatus.OnTime ||
    stopTimeStatus === StopTimeStatus.LateSoon ||
    stopTimeStatus === StopTimeStatus.LikelyLate
  ) {
    return `Ready`;
  }
  if (stopTimeStatus === StopTimeStatus.Late) {
    // This should not happen, but just in case -> putting a placeholder
    // Investiage if this happens.
    return `Late`;
  }

  return "";
}
