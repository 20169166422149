import moment from "moment";
import {
  DispatchPreview,
  Driver,
} from "@api/graphql/generated/generated-types";
import { DriverMapItem } from "@src/common/components/Widgets/DriverMapWidget";

export function makeDriverMapItemsFromDispatchPreviews(
  dispatchPreviews: Array<DispatchPreview>
): DriverMapItem[] {
  return dispatchPreviews
    .filter((dispatchPreview) => dispatchPreview.driver.isActive)
    .map((dispatchPreview: DispatchPreview) => {
      const durationToShipmenText = moment
        .duration(dispatchPreview.durationInSeconds, "seconds")
        .humanize();
      return makeDriverMapItem(
        dispatchPreview.driver,
        `${durationToShipmenText} - ${dispatchPreview.numCurrentTasks} tasks`
      );
    });
}

export function makeDriverMapItem(
  driver: Driver,
  subText: string | undefined
): DriverMapItem {
  return {
    id: driver.id || "",
    title: driver.firstName || "",
    subText: subText || "",
    image: driver.photoUrl || "",
    coordinate: driver.lastReportedLocation?.lngLat,
    isActive: driver.isActive,
  };
}
