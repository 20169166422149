import { Shipment, Stop } from "@api/graphql/generated/generated-types";
import moment from "moment";

export interface StopWithDeliveryDate extends Stop {
  deliverByDate?: string;
}

export function findIfShipmentIsMultiDay(
  stops: Stop[],
  shipmentDate?: string
): boolean {
  if (stops.length < 2) {
    return false;
  }

  const firstStopDate = stops[0].stopDate;
  // Check if shipmentDate is different from the first stop date
  if (shipmentDate && firstStopDate && shipmentDate !== firstStopDate) {
    return true;
  }

  let previousDate = firstStopDate;

  for (let i = 1; i < stops.length; i++) {
    const currentStop = stops[i];
    if (
      currentStop.stopDate &&
      previousDate &&
      currentStop.stopDate !== previousDate
    ) {
      return true;
    }
    previousDate = currentStop.stopDate;
  }

  return false;
}

export function findIfStopHasFutureDateDelivery(
  stop: StopWithDeliveryDate,
  shipment: Shipment
): boolean {
  const timeWindowClose = stop.timeWindow?.close;
  const deliverByDate = stop.deliverByDate;
  const stopDate = stop.stopDate || shipment.shipmentDate;

  if (!timeWindowClose || !stopDate) {
    return false;
  }

  const timeWindowCloseDate = moment(Number(timeWindowClose) * 1000).toDate();
  const stopMomentDate = moment(stopDate).startOf("day");
  const timeWindowCloseMomentDate = moment(timeWindowCloseDate).startOf("day");
  const deliverByMomentDate = deliverByDate
    ? moment(deliverByDate).startOf("day")
    : null;

  const isSameDay =
    stopMomentDate.isSame(timeWindowCloseMomentDate, "day") &&
    (!deliverByMomentDate || stopMomentDate.isSame(deliverByMomentDate, "day"));
  return !isSameDay;
}
